@import 'styles/includes.scss';
@import 'styles/variables';

.Button {
    display: inline-block;
    overflow: visible;

    &__Link {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 3;
        overflow: hidden;

        .Button--Primary &,
        .Button--Secondary &,
        .Button--SecondaryInvert & {
            border-radius: 50px;
        }

        .Button--Tertiary & {
            &:focus {
                outline: 1px dotted $colorPink;
            }
        }
    }

    &__IconContainer {
        align-self: center;
        display: flex;
        position: relative;

        &--Left {
            margin-right: 10px;
        }

        &--Right {
            margin-left: 10px;
        }

        svg {
            position: absolute;
            top: 0;
            left: 0;
        }
    }

    &__Container {
        font-family: $fontBrand;
        font-size: 1.4rem;
        padding: 1.15rem 2.45rem;
        border-radius: 50px;
        display: flex;
        letter-spacing: 0.02rem;
        flex-direction: row;
        align-self: center;
        align-items: center;
        text-decoration: none;
        position: relative;

        @include media(l) {
            letter-spacing: 0.03rem;
            font-size: 1.8rem;
            padding: 1.6rem 3.2rem;
            border-radius: 50px;
        }
    }

    &__Container.Button--Secondary {
        padding: 1.05rem 2.25rem;

        @include media(l) {
            padding: 1.4rem 3rem;
        }
    }

    // TODO: Refactor this to be pure BEM with same weight as regular modifiers
    .Button--Primary.Button--NoIcon,
    .Button--Secondary.Button--NoIcon,
    .Button--SecondaryInvert.Button--NoIcon {
        font-size: 1.8rem;
        padding: 1.5rem 3.2rem;
    }

    // Negates the behaviour of NoIcon
    &--PreventNoIconStyle {
        .Button__Container {
            font-size: 1.4rem !important;
            padding: 1.15rem 2.45rem !important;

            @include media(l) {
                letter-spacing: 0.03rem !important;
                font-size: 1.8rem !important;
                padding: 1.6rem 3.2rem !important;
            }
        }
    }

    &__Content {
        display: flex;
        flex-direction: row;
        align-items: center;
        z-index: 2;
        line-height: normal;
        letter-spacing: 0.02rem;

        @include media(l) {
            letter-spacing: 0.03rem;
        }

        &--TertiaryHover {
            display: block;
            position: relative;
            text-decoration: underline;
            text-decoration-color: inherit;
            text-decoration-thickness: 0.1rem;
            text-underline-offset: 4px;
            padding-right: 3.2rem;

            .Button__IconContainer {
                display: initial;
                top: 2px;
            }

            @include media(m) {
                text-decoration: none;
                display: flex;
                padding-right: 0;

                .Button__IconContainer {
                    display: flex;
                    top: 0;
                }

                &::before,
                &::after {
                    content: '';
                    backface-visibility: hidden;
                    width: 100%;
                    height: 0.2rem;
                    position: absolute;
                    background-color: inherit;
                    bottom: -4px;
                    left: 0px;
                    margin-top: 0;
                    margin-left: 0;
                    transition: transform 0.6s cubic-bezier(0.77, 0, 0.175, 1),
                        -webkit-transform 0.6s cubic-bezier(0.77, 0, 0.175, 1);
                }

                &::before {
                    transform: scaleX(1) skewX(0deg);
                    transform-origin: left center;
                    transition-delay: $transitionDelayFast;
                }
                &::after {
                    transform: scaleX(0) skewX(0deg);
                    transform-origin: right center;
                    transition-delay: 0s;
                }

                .Button:hover &:before {
                    transform: scaleX(0) skewX(45deg);
                    transition-delay: 0s;
                    transform-origin: right center;
                }

                .Button:hover &:after {
                    transform: scaleX(1) skewX(45deg);
                    transition-delay: $transitionDelayFast;
                    transform-origin: left center;
                }
            }
        }
        &--Pink.Button__Content--TertiaryHover::before,
        &--Pink.Button__Content--TertiaryHover::after {
            background-image: url(#{$assetPath}img/arrow-pink.svg);
            @include media(m) {
                background-image: none;
                background-color: $colorPink;
            }
        }

        &--White.Button__Content--TertiaryHover::before,
        &--White.Button__Content--TertiaryHover::after {
            background-image: url(#{$assetPath}img/arrow-white.svg);
            @include media(m) {
                background-image: none;
                background-color: $colorWhiteBackground;
            }
        }

        &--Black.Button__Content--TertiaryHover::before,
        &--Black.Button__Content--TertiaryHover::after {
            background-image: url(#{$assetPath}img/arrow-black.svg);
            @include media(m) {
                background-image: none;
                background-color: $colorBlack800;
            }
        }
    }

    &--Tertiary {
        background-color: transparent;
        font-size: 1.6rem;
        letter-spacing: 0.03rem;
        border: none;
        border-radius: 0;
        line-height: normal;
        padding: 2px 0 4px 0;

        &[disabled] {
            color: $colorGray300;

            svg {
                color: $colorGray300;
            }

            .Button__Content--TertiaryHover::before,
            .Button__Content--TertiaryHover::after {
                transition: none;
                background-color: $colorGray300;
            }
        }
    }

    &--Pink {
        color: $colorPink;

        &:focus {
            box-shadow: none;
            outline: 1px dotted $colorPink;
        }
    }

    &--White {
        color: $colorWhiteBackground;
    }

    &--Black {
        color: $colorBlack800;
    }

    &--Primary,
    &--Secondary,
    &--SecondaryInvert {
        transition: transform $transition;
        transform: translateY(0px);
        &:hover {
            transform: translateY(-4px);
        }
    }

    &--Primary {
        background-color: $colorPink;
        color: $colorWhite;
        border: none;

        .Icon svg {
            color: $colorWhite;
        }

        &[disabled] {
            background-color: $colorGray200;
            color: $colorGray500;
            svg {
                color: $colorGray500;
            }
            .Button--PressedIcon {
                display: none;
            }
        }

        .Button--PressedIcon {
            background-color: #c80058;
        }
    }

    &--Secondary {
        background-color: $colorWhiteBackground;
        border: 1px solid $colorBlack800;
        color: $colorBlack800;

        &.Button__Container--Pressed {
            border: none;
            background-color: $colorBlack800;
            color: $colorWhite;

            svg {
                color: $colorWhite;
            }
        }

        @include media(l) {
            border-width: 2px;
        }

        &:hover {
            background-color: $colorBlack800;
            color: $colorWhite;
            svg {
                color: $colorWhite;
            }
        }

        &[disabled] {
            color: $colorGray300;
            border: 1px solid $colorGray300;
            svg {
                color: $colorGray300;
            }
        }

        .Button--PressedIcon {
            background-color: $colorGray700;
        }
    }

    &--SecondaryInvert {
        background-color: $colorBlack800;
        border: 1px solid $colorWhiteBackground;
        color: $colorWhiteBackground;
    }

    &--IsClicked {
        background-color: $colorBlack800;
        color: $colorWhite;
        svg {
            color: $colorWhite;
        }
    }
}
