@import 'styles/includes.scss';

.RippleEffect {
    &__Container {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        border-radius: 50px;
        overflow: hidden;
    }
    &__Circle {
        transform: scale(0);
        border-radius: 100%;
        position: absolute;
        opacity: 0.75;
        animation-name: ripple;
        animation-duration: 850ms;
        background-color: #fff;
    }

    @keyframes ripple {
        to {
            opacity: 0;
            transform: scale(2);
        }
    }
}
